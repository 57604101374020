import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, ValidationError } from '@formspree/react';
import queryString from 'query-string';

import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';

import invites from './data/invitations.json'

invites.forEach(i => i.search = {
    postcode: i.postcode.replace(/[^a-z0-9]/gi, '').toUpperCase(),
    names: i.guests.map(g => g.name.replace(/[^a-z0-9]/gi, '').toUpperCase()),
})

const Rsvp = forwardRef((_, ref) => {
    const navigate = useNavigate();
    const [state, handleSubmit, reset] = useForm("mgvwypbr");

    const query = queryString.parse(useLocation().search)

    const [show, setShow] = useState(false);
    const [searchFailed, setSearchFailed] = useState(false);
    const [invite, setInvite] = useState();

    const [postcode, setPostcode] = useState(query["postcode"] || "");
    const [surname, setSurname] = useState(query["surname"] || "");

    const handleClose = () => {
        console.log("Modal closed")

        // Clear query parameters, allow user to search for someone else when they next open modal
        navigate({ hash: "", search: "" })

        setSurname("");
        setPostcode("");
        setInvite("");

        // Clear formspree state
        reset();

        setShow(false);
    }
    const handleShow = () => setShow(true);

    const open = () => {
        handleShow();
    }

    const findInvite = (event) => {
        event?.preventDefault();
        setInvite(null)

        const strippedPostcode = postcode.replace(/[^a-z0-9]/gi, '').toUpperCase();
        const strippedSurname = surname.replace(/[^a-z0-9]/gi, '').toUpperCase();

        console.log("Attempeting to find invite with parameters:", { strippedPostcode, strippedSurname })
        for (const invite of invites) {
            if (invite.search.postcode === strippedPostcode) {
                console.log("Post code found!")

                if (invite.search.names.some(n => n.includes(strippedSurname))) {
                    console.log("Name found!")
                    setInvite(invite)
                    setSearchFailed(false)
                    return;
                }
            }
        }

        console.log("Failed to find matching invite")
        setSearchFailed(true)
    }

    const submitRsvp = (event) => {
        event.preventDefault();
        handleSubmit(event);
    }

    useImperativeHandle(ref, () => ({
        open: open
    }));

    const SearchControls = <>
        <p>
            Please enter the following details from your invitation to start the RSVP process:
        </p>
        <Form onSubmit={findInvite}>
            {searchFailed === true && <Alert variant='danger'>Unable to find invite with a matching postcode / surname</Alert>}
            <Form.Group className="mb-3" controlId="surname">
                <Form.Label>Surname</Form.Label>
                <Form.Control type="text" placeholder="The surname of one of the guests on your invite" value={surname} onChange={event => setSurname(event.target.value)} required autoFocus />
            </Form.Group>
            <Form.Group className="mb-3" controlId="postcode">
                <Form.Label>Post Code</Form.Label>
                <Form.Control type="text" placeholder="The postcode your invitation was addressed to" value={postcode} onChange={event => setPostcode(event.target.value)} required />
            </Form.Group>

            <Row className='justify-content-center'>
                <Col sm={12} md={6}>
                    <Button variant="primary" size="sm" style={{ width: '100%' }} type="submit">
                        Find Invitation
                    </Button>
                </Col>
            </Row>
        </Form>
    </>

    const RsvpControls = <>
        <Form onSubmit={submitRsvp}>
            {searchFailed === true && <Alert variant='danger'>Unable to find invite with a matching postcode / surname</Alert>}
            <p className='text-center mt-4'>
                We hope you're able to join us for the big day!
            </p>
            <p className='text-center mt-4'>
                Please note that we are not able to accommodate children, pets or party poopers!
            </p>
            {state.errors && state.succeeded === false && <Alert variant="danger">There was an error submitting your RSVP</Alert>}
            <Row>
                {invite && invite.guests.map(guest => (
                    <Col lg={6} key={guest.name}>
                        <h2 className="guest-name text-center mb-2 mt-2">{guest.name}</h2>

                        <Form.Group className="mb-3" controlId={"rsvp-" + guest.name}>
                            <Form.Label>Are you able to attend the wedding?</Form.Label>
                            <Form.Select name={"rsvp-" + guest.name}>
                                <option value="y">Yes, I will be joining you on the 7th of June</option>
                                <option value="n">No, I won't be able to make the big day</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId={"diet-" + guest.name}>
                            <Form.Label>Do you have any dietary requirements?</Form.Label>
                            <Form.Control as="textarea" rows={3} name={"diet-" + guest.name}></Form.Control>
                        </Form.Group>
                    </Col>
                ))}
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Please provide an e-mail address we can send updates to:</Form.Label>
                        <Form.Control name="email" type="email" required />
                    </Form.Group>
                    <ValidationError prefix="Email"  field="email" errors={state.errors} className='text-danger' />
                </Col>
            </Row>

            <Row className='justify-content-center'>
                <Col sm={12} md={6} className='mt-4'>
                    <Button variant="primary" size="sm" style={{ width: '100%' }} type="submit">
                        Submit RSVP
                    </Button>
                </Col>
            </Row>
        </Form>
    </>

    const ThankYouControls = <>
        <h2 className='text-center'>Thank You</h2>
        <div className='text-center'>Thank you for submitting your RSVP!</div>
        <div className='text-center mt-4'>
        <Button onClick={handleClose}>Close</Button>
        </div>
    </>

    useEffect(() => {
        if (surname && postcode) {
            findInvite();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Modal show={show} onHide={handleClose} size={invite ? "xl" : "md"}>
            <Modal.Body>
                <div className='d-flex d-column'>
                    <h1 className='flex-fill'>RSVP</h1> 
                    <Button className='btn-close' onClick={handleClose} />
                </div>
                {!invite && !state.succeeded && SearchControls}
                {invite && !state.succeeded && RsvpControls}
                {state.succeeded && ThankYouControls}
            </Modal.Body>
        </Modal>
    )
})

export default Rsvp;
