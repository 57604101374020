
import logo from "./static/wl-black.png"

function Error404() {
    return (<>
        <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
            <div className="text-center">
                <img src={logo} alt="" style={{ maxWidth: "150px" }} />
                <h1>Page Not Found</h1>
                We could not find the page you are looking for. Please return to the <a href="/">homepage</a>
            </div>
        </div>
    </>)
}

export default Error404;