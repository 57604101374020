import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';

import './App.scss';

import Rsvp from './Rsvp'

function App() {

  const rsvp = useRef();

  const location = useLocation()
  useEffect(() => {
    console.log("Location changed", location)
    if (location.hash === "#rsvp") {
      if (rsvp.current) {
        rsvp.current.open();
      }
    }
  }, [location])

  return (
    <div>

      <Navbar bg="light" expand="lg" fixed="top" className="position-fixed top-0 start-0 container-fluid" id="navbar-example2" collapseOnSelect>
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="w-100 mb-2 mb-lg-0 justify-content-center" >
              <Nav.Link href="#schedule">Schedule</Nav.Link>
              <Nav.Link href="#venue">Venue</Nav.Link>
              <Nav.Link href="#food">Food</Nav.Link>
              <Nav.Link href="#accommodation">Accommodation</Nav.Link>
              <Nav.Link href="#attire">Attire</Nav.Link>
              <Nav.Link href="#gifts">Gifts</Nav.Link>
              <Nav.Link href="#faq">FAQ</Nav.Link>
              <Nav.Link href="#rsvp">RSVP</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <Rsvp ref={rsvp} />

      <div className="hero">
        <div className="logo"></div>
      </div>

      <div className="section at-a-glance section-white" id="at-a-glance">
        <Row>
          <Col xs={12}>
            <h1>At a Glance</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={11} lg={4} className='mb-3'>
            <div className="boquetImage"></div>
          </Col>
          <Col xs={11} lg={6} className="text-center text-lg-start">
            <p className="text-center text-md-start">
              The Wedding celebrations of Katie Baker &amp; Jamie Astin
              <br /><br />
              The Crooked Billet, Stoke Row, Henley-on-Thames, RG9 5PU
              <br /><br />
              Saturday, 7th June 2025. Please arrive by 3:00 PM
              <br /><br />
              Please <a href="#rsvp">RSVP</a> by the 1st October 2024
            </p>
          </Col>
        </Row>
      </div>

      <div className="section" id="schedule">
        <Row>
          <Col xs={12}>
            <h1 className="mb-0">Schedule </h1>
            <h2 className="mb-5">Saturday, 7th June 2025</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={11} className="schedule">
            <div className="schedule-item">
              <span className="time">3:00 PM</span> <span className="event-title">Guests Arrive</span> <br />
              Welcome drinks will be served on the front lawn of The Crooked Billet
            </div>
            <div className="schedule-divider"></div>
            <div className="schedule-item">
              <span className="time">3:30 PM</span> <span className="event-title">Ceremony</span> <br />
              Katie &amp; Jamie to exchange vows and rings
            </div>
            <div className="schedule-divider"></div>
            <div className="schedule-item">
              <span className="time">4:00 PM</span> <span className="event-title">Drinks, Canapés &amp; Games</span> <br />
              Time for the fun to start!
            </div>
            <div className="schedule-divider"></div>
            <div className="schedule-item">
              <span className="time">6:00 PM</span> <span className="event-title">Dinner &amp; Speeches</span> <br />
              Guests will be offered a choice from our specially curated wedding menu
            </div>
            <div className="schedule-divider"></div>
            <div className="schedule-item">
              <span className="time">8:00 PM</span> <span className="event-title">Cocktails &amp; Dancing</span> <br />
              An evening of celebration with a disco in the pub and relaxed lounge in the marquee
            </div>
            <div className="schedule-divider"></div>
            <div className="schedule-item">
              <span className="time">11:00 PM</span> <span className="event-title">Evening Food</span> <br />
              A chance to grab a quick bite to keep the energy going on the dance floor
            </div>
            <div className="schedule-divider"></div>
            <div className="schedule-item">
              <span className="time">Midnight</span> <span className="event-title">Lock-in / Carriages</span> <br />
              Disco to finish, drinks to continue for those still standing
            </div>
            <div className="schedule-divider"></div>
            <div className="schedule-item">
              <span className="time">1:00 AM</span> <span className="event-title">Final Carriages</span> <br />
              The day's celebrations draw to a close
            </div>
          </Col>
        </Row>
      </div>

      <div className="section section-white" id="venue">
        <Row>
          <Col xs={12}>
            <h1 className="mb-0">The Venue</h1>
            <h2 className="mb-5"><span className="address-component">The Crooked Billet </span><span className="address-component"> Stoke Row </span><span className="address-component"> Henley-on-Thames </span><span className="address-component"> RG9 5PU</span></h2>
          </Col>
        </Row>
        <Row className="justify-content-center flex-row-reverse">
          <Col xs={11} md={4} className="mb-2">
            <div className="venueImage"></div>
          </Col>
          <Col xs={11} md={6}>
            <p className="text-center text-md-start">
              We first came across The Crooked Billet after a hike in The Chilterns and thought it would make the perfect venue for our wedding. In fact, we actually booked The Crooked Billet before we were engaged!
            </p>
            <p className="text-center text-md-start">
              The Crooked Billet was the first restaurant in the UK referred to as a "Gastro Pub", back in 1989. In partnership with owner &amp; chef Paul Clerehugh, we have designed a relaxed and informal celebration for our wedding.
            </p>
            <div className="text-center text-md-start">
              <Button variant="primary" className="d-block d-md-inline-block mt-4 ms-md-1 me-md-1" href="https://maps.app.goo.gl/4Xm9i1C1nEfgT7X28" target="_blank" rel="noreferrer">Google Maps</Button>
              <Button variant="primary" className="d-block d-md-inline-block mt-4 ms-md-1 me-md-1" href="https://www.thecrookedbillet.co.uk/" target="_blank" rel="noreferrer">The Crooked Billet Website</Button>
            </div>
          </Col>
        </Row>
      </div>

      <div className="section" id="food">
        <Row>
          <Col xs={12}>
            <h1>Food</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={11} md={4} className="mb-2">
            <div className="foodImage"></div>
          </Col>
          <Col xs={11} md={6}>
            <p className="text-center text-md-start">
              We are thrilled to have curated a special menu full of our favourite food &amp; drink for you to enjoy.
              Guests will be able to choose from the wedding menu on the day.
            </p>
            <p className="text-center text-md-start">
              We can cater for all dietary requirements, please make sure to specify any preferences or allergies when submitting your RSVP.
            </p>
          </Col>
        </Row>
      </div>









      <div className="section section-white" id="accommodation">
        <div className="row">
          <div className='col-12'>
            <h1>Accommodation</h1>
          </div>
        </div>
        <div className="row justify-content-center flex-row-reverse">
          <div className='col-11 col-lg-4 mb-4'>
            <div className='accomImage'> </div>
          </div>
          <div className='col-11 col-lg-6'>
            <p className="text-center text-md-start">
              The Crooked Billet is set in The Chilterns countryside, a 15 minute drive from Henley-on-Thames.
              There are limited accommodation options nearby, but below are some suggestions:
            </p>
            <ul className="list accom-list">
              <li className="text-center text-md-start">
                <a href="https://hotels.jdwetherspoon.com/hotels/the-catherine-wheel-hotel/" target="_blank" rel="noreferrer">The Catherine Wheel </a>
                <span className="d-none d-md-inline"> - </span><span className="d-inline d-md-none"> <br /> </span>
                A Wetherspoons pub located in Henley-on-Thames, rooms starting from £49.
              </li>
              <li className="text-center text-md-start">
                <a href="https://www.cp-reading.co.uk/" target="_blank" rel="noreferrer">The Crowne Plaza</a>
                <span className="d-none d-md-inline"> - </span><span className="d-inline d-md-none"> <br /> </span>
                Located in Reading, a 20 minute journey by car. Rooms starting from £85.
              </li>
              <li className="text-center text-md-start">
                <a href="https://www.badgemorepark.com/" target="_blank" rel="noreferrer">Badgemore Park</a>
                <span className="d-none d-md-inline"> - </span><span className="d-inline d-md-none"> <br /> </span>
                Golf course and hotel located just outside Henley-on-Thames. Rooms starting from £125.
              </li>
              <li className="text-center text-md-start">
                <a href="https://www.premierinn.com/gb/en/hotels/england/berkshire/reading/reading-central.html" target="_blank" rel="noreferrer">Premier Inn</a>
                <span className="d-none d-md-inline"> - </span><span className="d-inline d-md-none"> <br /> </span>
                There are a number of Premier Inns in Reading. Rooms starting from £100.</li>
              <li className="text-center text-md-start">
                There is also a large number of AirBnB properties available in the local area
              </li>
            </ul>
          </div>
        </div>
      </div>







      <div className="section" id="attire">
        <Row>
          <Col xs={12}>
            <h1>Attire</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={11} lg={4} className='mb-3'>
            <div className='attireImage'></div>
          </Col>
          <Col xs={11} lg={6}>
            <p className="text-center text-md-start">
              We would love you to wear whatever you feel great in! However, we also know some people love more direction:
            </p>
            <ul className="list">
              <li>Summer formal. Dresses and suits encouraged</li>
              <li>Patterns and bright colours welcome</li>
              <li>Ties not essential</li>
              <li>No trainers or sportswear</li>
              <li>No denim or jeans</li>
            </ul>
          </Col>
        </Row>
      </div>






      <div className="section section-white" id="gifts">
        <Row>
          <Col xs={12}>
            <h1>Gifts</h1>
          </Col>
        </Row>
        <Row className="justify-content-center flex-row-reverse">
          <Col xs={11} md={4} className='pb-4'>
            <div className='giftImage'></div>
          </Col>
          <Col xs={11} md={6}>
            <p className="text-center text-md-start">
              Your presence is our present, we do not expect gifts. However, if you feel compelled to get us a little something we would appreciate a contribution to our honeymoon fund.
            </p>
            <p className="text-center text-md-start">
              We are planning a trip to paradise in Bali to celebrate our marriage. Travel tips and recommendations welcome!
            </p>
            <div>
              <Button variant="primary" className="d-block d-md-inline-block mt-4" href="https://prezola.com/buy/view/196194" target="_blank" rel="noreferrer">Gift Registry</Button>
            </div>
          </Col>
        </Row>
      </div>

      <div className="section" id="faq">
        <Row>
          <Col xs={12}>
            <h1 >FAQ</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={10}>
            <p className="question">
              <b>Q: Will the wedding be held inside or outside?</b>
              <br />
              A: We are hoping for good weather to allow us to hold the ceremony, drinks & canapes outside. We have a plan b should the English weather conspire against us. No need for coats and umbrellas, but we would (hopefully!) recommend sun cream.
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Are you doing anything on Sunday?</b>
              <br />
              A: Hangovers permitting, we are hoping to have a casual drink or two at <a href="https://www.risingsunwh.co.uk/" target="_blank" rel="noreferrer">The Rising Sun</a> on Sunday from 11:00, attendance optional.
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Do we need to arrange taxis in advance?</b>
              <br />
              A: The Crooked Billet staff will be on-hand to help book taxis during the evening. However, if you feel more comfortable booking in advance we recommend <a href="https://www.500cars.com/">500  Cars</a>, based in Reading.
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Can I leave my car overnight at The Crooked Billet?</b>
              <br />
              A: Yes, there is a large car park on-site where you are welcome to leave your car. Please ensure they are collected by 11:00 on Sunday morning.
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Can I bring my children?</b>
              <br />
              A: Unfortunately no, the day's festivities will involve eating and drinking to excess and Katie will be swearing often. We would therefore ask that you leave your children at home.
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Can I bring my pets?</b>
              <br />
              A: We have had to have a long chat with Monty and Dexter about why this is a human-only event. We'd be happy to talk to your pet too!
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Can I take pictures throughout the day?</b>
              <br />
              A: We would love for you to take as many pictures and videos as you'd like, <u><b>after the ceremony</b></u>. We have a fantastic photography team that will be capturing the whole day.
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Can I share photos and videos on social media?</b>
              <br />
              A: We'd love for you to share photos and memories with us and online. Please ensure no double chins!
            </p>
            <div className="question-divider"></div>
            <p className="question">
              <b>Q: Will my phone work at the venue?</b>
              <br />
              A: There is very limited phone signal and the Wifi is temperamental. So prepare to be off-grid for the day.
            </p>
          </Col>
        </Row>
      </div>

    </div>
  );
}

export default App;
